<template>
  <b-modal
    id="cms-create-floating-menus"
    title="New Floating Menu"
    no-close-on-backdrop
    @ok="onOk"
  >
    <validation-observer
      ref="cmsMenuForm"
    >
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="title"
          rules=""
        >
          <b-form-group label="Title">
            <b-form-input
              id="title"
              v-model="cmsFloatingMenuForm.title"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="image_src"
          rules="required"
        >
          <b-form-group label="Image">
            <CmsFileSelect
              :value="cmsFloatingMenuForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (cmsFloatingMenuForm.imageSrc = value || '')"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="display_condition"
          rules="required"
        >
          <b-form-group label="Display condition">
            <b-select
              v-model="cmsFloatingMenuForm.displayCondition"
              :state="errors[0] ? false : null"
            >
              <b-select-option
                v-for="(item, index) of conditions"
                :key="index"
                :value="item"
              >
                {{ item }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="display_condition"
          rules="required"
        >
          <b-form-group label="External link?">
            <b-form-checkbox
              v-model="cmsFloatingMenuForm.isExternalLink"
              switch
              :state="errors[0] ? false : null"
              size="lg"
            />
          </b-form-group>
        </validation-provider>
        <b-form-group
          v-if="cmsFloatingMenuForm.isExternalLink"
          label="External link target"
        >
          <b-form-input
            v-model="cmsFloatingMenuForm.externalLinkTo"
            placeholder="https://google.com"
          />
        </b-form-group>
        <b-form-group
          v-else
          label="Internal link target"
        >
          <b-form-input
            v-model="cmsFloatingMenuForm.internalLinkTo"
            placeholder="/dashboard"
          />
        </b-form-group>
        <validation-provider
          v-slot="{ errors }"
          name="position"
          rules="required"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="cmsFloatingMenuForm.position"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
  },
  data() {
    return {
      required,
      
      cmsFloatingMenuForm: {
        position: 0,
        title: '',
        imageSrc: '',
        displayCondition: 'ALL',
        isExternalLink: false,
        internalLinkTo: '',
        externalLinkTo: '',
      },
      conditions: ['ALL', 'LOGGED_IN', 'NOT_LOGGED_IN'],
    }
  },
  computed: {
    ...mapState({
      isCreatingSiteFloatingMenus: (state) =>
        state.cms.isCreatingSiteFloatingMenus,
    }),
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsFloatingMenus']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-floating-menus'))
      }
    },
    async onSubmit(cb) {
      this.$refs.cmsMenuForm.validate().then( async (success) => {
        if (success) {
          await this.createCmsFloatingMenus({
            siteId: this.siteId,
            floatingMenus: [this.cmsFloatingMenuForm],
          })

          cb()
        }
      })
    },
  },
}
</script>
