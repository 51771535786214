var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"cms-create-floating-menus","title":"New Floating Menu","no-close-on-backdrop":""},on:{"ok":_vm.onOk}},[_c('validation-observer',{ref:"cmsMenuForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors[0] ? false : null},model:{value:(_vm.cmsFloatingMenuForm.title),callback:function ($$v) {_vm.$set(_vm.cmsFloatingMenuForm, "title", $$v)},expression:"cmsFloatingMenuForm.title"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"image_src","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Image"}},[_c('CmsFileSelect',{attrs:{"value":_vm.cmsFloatingMenuForm.imageSrc,"state":errors[0] ? false : null},on:{"update":function (value) { return (_vm.cmsFloatingMenuForm.imageSrc = value || ''); }}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"display_condition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Display condition"}},[_c('b-select',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.cmsFloatingMenuForm.displayCondition),callback:function ($$v) {_vm.$set(_vm.cmsFloatingMenuForm, "displayCondition", $$v)},expression:"cmsFloatingMenuForm.displayCondition"}},_vm._l((_vm.conditions),function(item,index){return _c('b-select-option',{key:index,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)]}}])}),_c('validation-provider',{attrs:{"name":"display_condition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"External link?"}},[_c('b-form-checkbox',{attrs:{"switch":"","state":errors[0] ? false : null,"size":"lg"},model:{value:(_vm.cmsFloatingMenuForm.isExternalLink),callback:function ($$v) {_vm.$set(_vm.cmsFloatingMenuForm, "isExternalLink", $$v)},expression:"cmsFloatingMenuForm.isExternalLink"}})],1)]}}])}),(_vm.cmsFloatingMenuForm.isExternalLink)?_c('b-form-group',{attrs:{"label":"External link target"}},[_c('b-form-input',{attrs:{"placeholder":"https://google.com"},model:{value:(_vm.cmsFloatingMenuForm.externalLinkTo),callback:function ($$v) {_vm.$set(_vm.cmsFloatingMenuForm, "externalLinkTo", $$v)},expression:"cmsFloatingMenuForm.externalLinkTo"}})],1):_c('b-form-group',{attrs:{"label":"Internal link target"}},[_c('b-form-input',{attrs:{"placeholder":"/dashboard"},model:{value:(_vm.cmsFloatingMenuForm.internalLinkTo),callback:function ($$v) {_vm.$set(_vm.cmsFloatingMenuForm, "internalLinkTo", $$v)},expression:"cmsFloatingMenuForm.internalLinkTo"}})],1),_c('validation-provider',{attrs:{"name":"position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position"}},[_c('b-form-input',{attrs:{"id":"position","state":errors[0] ? false : null},model:{value:(_vm.cmsFloatingMenuForm.position),callback:function ($$v) {_vm.$set(_vm.cmsFloatingMenuForm, "position", $$v)},expression:"cmsFloatingMenuForm.position"}})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }